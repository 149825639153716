/* Global Styles */
.hero-section { 
    background-color: black;
}

.header-container { 
    background-image: url(../../../images/4kSpaceBackground.png); 
    background-position: center; 
    margin-bottom: 10px;
    height: 750px; 
    width: 100%;
    position: relative;
}

/* Background Overlay */
.blck-bckgrnd { 
    background-color: rgba(0, 0, 0, 0.6); /* Simplified opacity using rgba */
    height: 150px; /* Increased height */
    width: 95%; /*Increased width*/
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center horizontally */
    z-index: 0;
}

/* Text Styles */
.company-vision { 
    color: lavender; 
    text-align: center; 
    position: absolute; 
    top: 45%; /* Adjusted top position */
    left: 50%;
    transform: translate(-50%, -50%); /* Center horizontally */
    z-index: 2;
    font-size: 2rem; /* Increased font size */
    width: 90%; /* Spread text across the width of the box */
    line-height: 1.4; /* Added line height for better readability */
}

/* Button Styles */
.explore-shop-btn { 
    margin-top: 20px; /* Adjusted for better spacing */ 
    background-color: black;
    position: absolute; 
    top: 60%; /* Positioned below the text */
    left: 50%;
    transform: translate(-50%, -50%); /* Center horizontally */
    z-index: 3; 
    opacity: 85%;
}

.explore-shop-btn:hover { 
    background-color: aqua; 
    color: black;
}

.explore-shop-btn-text { 
    color: white; 
    text-align: center;
}

.explore-shop-btn-text:hover { 
    color: black;
}

/* ListGroup Item Styles */
.header-listgroup-item { 
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; 
    border: none;
}

.header-listgroup-item:hover { 
    color: cyan;
} 

.header-listgroup-item:focus,
.header-listgroup-item:active { 
    color: purple;
}

/* Responsive Styles */
@media only screen and (max-width: 767px) { 
    .blck-bckgrnd { 
    top: 35%;
}

    .company-vision { 
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%); /* Center horizontally */
        font-size: 1.5rem; /* Adjust font size for smaller screens */
        width: 90%; /* Ensure text spreads across the width of the box */
        line-height: 1.4; /* Maintain line height for readability */
    } 

    .explore-shop-btn { 
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%); /* Center horizontally */
    } 

    .header-container { 
        background-image: url(../../../images/4kSpaceBackground.png); 
        background-position: center; 
        margin-bottom: 10px;
        height: 625px; 
        width: 100%;
        position: relative;
    }
}
