/* Shop.css */
.shop-page .product-card,
.shop-page .category-card { 
    background-color: lightpink;
    cursor: pointer;
    transition: transform 0.4s ease-in-out; /* Smooth and longer transition */
}

.shop-page .product-card:hover,
.shop-page .category-card:hover {
    transform: scale(1.05); /* Scales up slightly on hover */
}

/* To ensure it smoothly transitions back to the original size */
.shop-page .product-card,
.shop-page .category-card {
    transform: scale(1); /* Ensures it returns to the original size */
}
