/* Navbar-Background */
.nb-bg { 
    background-color: black; 
    padding-bottom: 10px; 
    padding-left: 10px;
    padding-right: 10px; 
    display: flex; 
    justify-content: space-between;
    align-items: center;
    
}

#navbarNav { 
    display: flex; 
    justify-content: flex-end;
    align-items: center;
} 

.nav-spacing { 
    margin-left: auto;
}

.nav-link { 
    color:white; 
}

.nav-link:active { 
    color: purple;
} 

.nav-link.disabled { 
    color: rgba(161, 154, 154, 0.5);
}

.nav-link:hover { 
    color: cyan;
} 

.nav-link:focus { 
    color: purple;
} 

.profile-pic { 
    color: white;
    height: 20px;
    width: 100%; 
} 

.profile-pic:hover { 
    color: aqua;
}
