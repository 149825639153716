/*                                                                              VideoGeneratorHome.js                                                                                */
.video-generator-home { 
        background-color: #800000;
        color: white;
        padding: 20px;
        text-align: center; 
} 

/*                                                                                    ContentIdeas.js                                                                                */
.th-theme { 
    background-color: black; 
    color: white;
} 

.glass-container {
    background: rgba(128, 0, 0, 0.5); /* Red wine tint with 50% opacity */
    border: 1px solid rgba(255, 255, 255, 0.3); /* White border with 30% opacity */
    border-radius: 10px;
    box-shadow: 0 0 10px black; /* White box shadow with 30% opacity */
    backdrop-filter: blur(10px); /* Apply a blur effect to the background */ 
  } 

  .page-link { 
    color: white;
  } 

  .page-link:focus { 
    color: red; 
  }
  