html, body {
  height: 100%;
  margin: 0;
  background-color: black; 
  color: silver;
}

.contact-us-form { 
  background-color: #0c0c0c;
} 

.form-label { 
  margin-top: 5px;
}

.contact-section { 
  border: solid white 1px;
  margin: 5px;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
}

.content {
  flex: 1;
}

.footer {
  background-color: black;
  padding: 20px 0;
  width: 100%;
  margin-top: auto; /* Pushes footer to the bottom */
  text-align: center;
  color: silver;
} 

.shop-section { 
  border: solid white 1px;
  margin: 5px;
}

.shop-section-card { 
  display: flex; 
  justify-content: center; 
  align-items: center;
  margin-top: 50px; 
  height: 200px;
  width: 100%;
  color: silver; 
  background-color: black;
}

.youtube-section { 
  border: solid white 1px; 
  margin: 5px;
}

.testimonials-section { 
  border: solid white 1px; 
  margin: 5px;
}